import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import Sidebar from "../partials/Sidebar";
import { Header } from "../parts/Header";
import { Store } from "./store";

const LoginLayout = ({ children }) => {
  const location = useLocation();
  const history = useHistory();
  const { pathname } = location;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [scrollTo, setScrollTo] = useState(null);
  const [currentLink, setCurrentLink] = useState("home");
  const scrollableElementRef = useRef(null); // Reference to the scrollable element

  useEffect(() => {
    const onScroll = () => {
      console.log("scrolling...");
      const scrolled = scrollableElementRef.current.scrollTop > 50; // Use scrollTop instead of scrollY
      // console.log("IsScrolled", scrolled);
      setIsScrolled(scrolled);
    };

    const scrollableElement = scrollableElementRef.current;
    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", onScroll);

      // Clean up the event listener when the component is unmounted
      return () => {
        scrollableElement.removeEventListener("scroll", onScroll);
      };
    }
  }, []); // Empty dependency array means this effect runs once on mount and clean up on unmount
  const setSidebarExpanded = (e) => {
    e.preventDefault();
    setSidebarOpen(true);
  };
  const setSidebarClosed = (e) => {
    e.preventDefault();
    setSidebarOpen(false);
  };

  const handleOpenSidebarClick = (e, value) => {
    e.preventDefault();
    setSidebarOpen(e, value);
  };
  const handleNavClick = (scrollTo, linkName, from) => {
    console.log("handleNavClick --> scrollTo", scrollTo);

    setScrollTo(scrollTo);
    //how to determine if I need to scroll to an element or navigate to a page
    console.log("handleNavClick", scrollTo, linkName);
    //if linkname and pathname are the same, then scroll to the element
    //if linkname and pathname are different, then navigate to the page and scroll to the element
    if (linkName === pathname) {
      console.log("linkName === pathname, execute scroll");
      executeScroll(scrollTo, linkName);
    } else {
      console.log("linkName !== pathname");
      history.push("/");
      setTimeout(() => {
        executeScroll(scrollTo, linkName);
      }, 100);
    }
    setTimeout(() => {
      if (from === "sidebar") {
        setSidebarOpen(false);
      }
    }, 500);
  };

  const handleMobileNavClick = (scrollTo, linkName, from) => {
    console.log("handleMobileNavClick --> scrollTo", scrollTo);

    setScrollTo(scrollTo);
    //how to determine if I need to scroll to an element or navigate to a page
    console.log("handleMobileNavClick", scrollTo, linkName);
    //if linkname and pathname are the same, then scroll to the element
    //if linkname and pathname are different, then navigate to the page and scroll to the element
    if (linkName === pathname) {
      console.log("handleMobileNavClick linkName === pathname, execute scroll");
      // executeScroll(scrollTo, linkName);
      history.push(`#${scrollTo}`);
    } else {
      console.log("handleMobileNavClick linkName !== pathname");
      history.push("/");
      setTimeout(() => {
        executeScroll(scrollTo, linkName);
      }, 100);
    }
    setTimeout(() => {
      setSidebarOpen(false);
    }, 250);
  };

  const executeScroll = (scrollTo, linkName) => {
    // alert(`scrollTo: ${scrollTo}, linkName: ${linkName}`);
    console.info("executeScroll", scrollTo);
    setCurrentLink(linkName);

    const element = document.getElementById(scrollTo);
    console.info("executeScroll", element);
    if (element) {
      console.info("executeScroll element found", element);
      // alert(element.getBoundingClientRect());
      // alert(`element: ${element.id}`);
      element.scrollIntoView({
        behavior: "smooth",
        block: "start", // Corrected from alignToTop to block: "start"
        inline: "start",
      });
    } else {
      // alert(`Element not found: ${scrollTo}`);
      console.error("Element not found:", scrollTo);
    }
  };

  // This is the main function where which pass two ref parameters of Parent element & Child element
  const scrollIntoView = (parent, child) => {
    const parentBounding = parent.getBoundingClientRect(),
      clientBounding = child.getBoundingClientRect();

    const parentBottom = parentBounding.bottom,
      parentTop = parentBounding.top,
      clientBottom = clientBounding.bottom,
      clientTop = clientBounding.top;

    if (parentTop >= clientTop) {
      scrollTo(parent, -(parentTop - clientTop), 300);
    } else if (clientBottom > parentBottom) {
      scrollTo(parent, clientBottom - parentBottom, 300);
    }
  };
  // function scrollTo(element, to, duration) {
  //   let start = element.scrollTop,
  //     currentTime = 0,
  //     increment = 20;

  //   let animateScroll = function () {
  //     currentTime += increment;

  //     let val = easeInOutQuad(currentTime, start, to, duration);
  //     element.scrollTop = val;

  //     if (currentTime < duration) {
  //       setTimeout(animateScroll, increment);
  //     }
  //   };

  //   animateScroll();
  // }
  // // Function for smooth scroll animation with the time duration
  // function easeInOutQuad(time, startPos, endPos, duration) {
  //   time /= duration / 2;

  //   if (time < 1) return (endPos / 2) * time * time + startPos;
  //   time--;
  //   return (-endPos / 2) * (time * (time - 2) - 1) + startPos;
  // }

  const navigation = [
    {
      name: "Home",
      id: "home",
      href: "/",
      scrollTo: "top",
      onClick: () => handleNavClick("top", "/"),
      desktop: true,
    },
    {
      name: "Overview",
      id: "overview",
      href: "/",
      scrollTo: "overview",
      /*options: [
        {
          name: "Overview",
          id: "overview",
          href: "/",
          scrollTo: "overview",
          description:
            "Learn about our solutions for law firms and legal departments",
          href: "/",
          onClick: () => handleNavClick("overview", "/"),
        },
        {
          name: "Law Firms",
          id: "lawFirms",
          description:
            "Learn about our solutions for law firms and legal departments",
          href: "/",
          onClick: () => handleNavClick("lawFirms", "/"),
        },
        {
          name: "Insurance Carriers",
          id: "insurance",
          description: "Learn about our solutions for insurance carriers.",
          href: "/",
          onClick: () => handleNavClick("insurance", "/"),
        },
        {
          name: "Corporate Legal",
          id: "corporate",
          description:
            "Learn about our solutions for corporate legal departments.",
          href: "/",
          onClick: () => handleNavClick("corporate", "/"),
        },
        {
          name: "Service Providers",
          id: "vendors",
          description: "Learn how to become a vendor on our platform.",
          href: "/",
          onClick: () => handleNavClick("vendors", "/"),
        },
      ],*/
      onClick: () => handleNavClick("overview", "/"),
      desktop: true,
    },
    {
      name: "Solutions",
      id: "solutions",
      scrollTo: "solutions",
      href: "/",
      // options: [
      //   {
      //     name: "Law Firms",
      //     id: "lawFirms",
      //     description:
      //       "Learn about our solutions for law firms and legal departments",
      //     href: "/",
      //     onClick: () => handleNavClick("lawFirms", "/"),
      //   },
      //   {
      //     name: "Insurance Carriers",
      //     id: "insurance",
      //     description: "Learn about our solutions for insurance carriers.",
      //     href: "/",
      //     onClick: () => handleNavClick("insurance", "/"),
      //   },
      //   {
      //     name: "Corporate Legal",
      //     id: "corporate",
      //     description:
      //       "Learn about our solutions for corporate legal departments.",
      //     href: "/",
      //     onClick: () => handleNavClick("corporate", "/"),
      //   },
      //   {
      //     name: "Service Providers",
      //     id: "vendors",
      //     description: "Learn how to become a vendor on our platform.",
      //     href: "/",
      //     onClick: () => handleNavClick("vendors", "/"),
      //   },
      // ],
      onClick: () => handleNavClick("solutions", "/"),
      desktop: false,
    },

    {
      name: "Features",
      id: "features",
      href: "/",
      scrollTo: "features",
      onClick: () => handleNavClick("features", "/"),
      desktop: true,
    },

    /*{
      name: "Services",
      id: "services",
      href: "/",
      scrollTo: "services",
      onClick: () => handleNavClick("services", "/"),
      desktop: true,
    },*/
    /*{
      name: "Vendors",
      id: "vendors",
      href: "/",
      scrollTo: "vendors",
      onClick: () => handleNavClick("vendors", "/"),
      desktop: true,
    },*/
    // {
    //   name: "Team",
    //   id: "team",
    //   href: "/",
    //   scrollTo: "team",
    //   onClick: () => handleNavClick("team", "/"),
    // },
    {
      name: "Contact Us",
      id: "contact",
      href: "/",
      scrollTo: "contact",
      onClick: () => handleNavClick("contact", "/"),
      desktop: true,
    },
  ];

  return (
    // <Store>
    <div className="flex h-screen ">
      {/* Content area */}
      <div
        ref={scrollableElementRef}
        className="relative flex flex-col flex-1  overflow-x-hidden"
      >
        {" "}
        <Sidebar
          showHome={false}
          profile={{}}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          setSidebarClosed={setSidebarClosed}
          navigation={navigation}
          isScrolled={isScrolled}
          scrollToLocation={scrollTo}
          setScrollTo={setScrollTo}
          currentLink={currentLink}
          setCurrentLink={setCurrentLink}
          handleNavClick={(scrollTo, linkName, from) => {
            handleNavClick(scrollTo, linkName, from);
          }}
          handleMobileNavClick={(scrollTo, linkName, from) => {
            handleMobileNavClick(scrollTo, linkName, from);
          }}
          handleOpenSidebarClick={(e) => setSidebarExpanded(e)}
        />
        <Header
          isScrolled={isScrolled}
          scrollToLocation={scrollTo}
          showHome={false}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarExpanded}
          setSidebarClosed={setSidebarClosed}
          navigation={navigation}
          setScrollTo={setScrollTo}
          currentLink={currentLink}
          setCurrentLink={setCurrentLink}
          handleNavClick={(scrollTo, linkName, from) => {
            handleNavClick(scrollTo, linkName, from);
          }}
          handleOpenSidebarClick={(e) => setSidebarExpanded(e)}
        />
        <main>{children}</main>
        <footer className="bg-gray-50" aria-labelledby="footer-heading">
          <h2 id="footer-heading" className="sr-only">
            Footer
          </h2>

          <div className="px-4 py-6 border-t border-gray-200  md:flex md:items-center md:justify-between">
            <div className="flex flex-col md:grid md:grid-cols-3 items-center w-full space-x-6 md:order-2">
              <div className="col-start-1 flex items-center justify-center  text-base text-gray-400 md:mt-0 md:order-1">
                <a
                  href="mailto:info@getreadyresponse.com"
                  className="text-indigo-500 hover:text-indigo-500"
                >
                  Email us at info@getreadyresponse.com
                </a>
              </div>
              <div className="col-start-2 flex items-center justify-center text-base text-gray-400 md:mt-0 md:order-1">
                &copy; 2024 Breachlink. All rights reserved.
              </div>
              <div className="col-start-3 flex items-center justify-center text-base text-gray-400 md:mt-0 md:order-1">
                <a
                  href="https://www.breachlink.com/"
                  className="text-indigo-500 hover:text-indigo-500"
                >
                  Interested in Breachlink?
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default LoginLayout;
